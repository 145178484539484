import React, { useEffect } from "react";
import '../styles/Project-1.css';

import titleImg from '../assets/images/project-1/cafeverse-title.svg';
import userStatistic from '../assets/images/project-1/statistic.png';
import userPersona from '../assets/images/project-1/user_persona.svg';
import usersDefined from '../assets/pdfs/project-1/Users_Defined.pdf';
import marketResearch from '../assets/pdfs/project-1/Market_Research.pdf';
import similarProducts from '../assets/images/project-1/similar_products.png';
import marketFeatures from '../assets/images/project-1/market_features.png';
import userJourney from '../assets/images/project-1/user_journey_map.png';
import paperWireframes from '../assets/pdfs/project-1/Paper_Wireframes.pdf';
import paperWireframeImg from '../assets/images/project-1/paper_wireframe.jpg';
import digitalWireframes from '../assets/images/project-1/digital-wireframes.svg';
import mockups from '../assets/images/project-1/mockups.svg';
import highFidelity from '../assets/images/project-1/high-fidelity.svg';
import highFidelity2 from '../assets/images/project-1/high-fidelity2.svg';
import prototypingAndDesign from '../assets/pdfs/project-1/Prototyping_Design.pdf';

const Project1 = () => {
    
    useEffect(() => {
        // Scroll to the top of the page when this component mounts
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="project1-section">
            <div className="project1-content">

                {/* title, subtitle, and teammates */}
                <div className="one-intro-section">
                    <div className="one-intro-image">
                        <img src={titleImg} alt="Phone screens showing the UI of the project."/>
                    </div>
                    <div className="one-intro-text">
                        <div className="one-title">
                            <div className="project1-heading-container">
                                <h1 id="project1-heading-1">Café</h1>
                                <h1 id="project1-heading-2">verse</h1>
                            </div>
                            <h2 className="one-intro-text">Fandom Event Platform UX</h2>
                            <p className="one-intro-text">Teammates: <a href="https://www.linkedin.com/in/casandra-alvarez/" target="_blank" rel="noreferrer noopener">Casandra Alvarez</a></p>
                        </div>

                        {/* overview */}
                        <div className="one-overview">
                            <h3 id="one-overview-h3" className="one-intro-text">Overview</h3>
                            <p className="one-intro-text">Caféverse is a mobile platform designed to simplify event management for organizers, connect attendees with fandom pop-up events, assist venues in showcasing their space and services, and help vendors sell their products. The app aims to build vibrant communities by facilitating these connections.</p>
                        </div>  
                    </div>
                </div>

                {/* prototype on figma */}
                <div className="one-prototype-link">
                    <p id="one-prototype-line">Check out the high-fidelity prototype on Figma!</p>
                    <a href="https://www.figma.com/proto/iWDS0swl2aznG1TwfH7SFw/CafeVerse-High-Fidelity-Prototype?node-id=14-949&t=DY9inFhRS4cECYVm-1&scaling=scale-down&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=14%3A949" target="_blank" rel="noopener noreferrer">
                        <button type="button">View Prototype</button>
                    </a>
                </div>

                {/* role and responsibilities */}
                <div className="one-role">
                    <h3>My Role & Responsibilities</h3>  
                    <p>I was the Project Manager, collaborating on user research, wireframing, prototyping, and testing. I also assisted in content strategy and user interface design.</p>  
                </div>  

                {/* initial user research */}
                <div className="one-research">
                    <div className="one-research-text">
                        <h3>Initial User Research</h3>
                        <p>We conducted comprehensive user analysis, including surveys and interviews, to understand the needs of various stakeholders in the café event ecosystem. Our research focused on primary users such as café owners, event organizers, attendees, and vendors, as well as secondary users across different age groups. We gathered insights on user demographics, technology proficiency, event preferences, and fandom interests. This research helped us identify key user goals and tasks, informing the development of features to connect and serve our diverse user base effectively. </p>
                        <p>The below document showcases this user analysis. It includes detailed user profiles, stakeholder information, survey results, and user goals. The content is valuable for understanding target audiences and their needs in the context of café events and related acitivites.</p>
                        <a href={usersDefined} target="_blank" rel="noopener noreferrer">Download Users Defined PDF</a>
                    </div>
                    <div className="one-research-image">
                        <img src={userStatistic} alt="User statistic data."/>
                    </div>
                </div>

                {/* market research */}
                <div className="one-market">
                    <div className="one-market-text">
                        <h3>Market Research</h3>
                        <p>We analyzed similar platforms like Eventbrite, Guidebook, and AllEvents to identify strengths and weaknesses in existing event management and discovery apps. This research highlighted the need for better location services, customization options, and a more engaging user experience, which informed our design decisions for Caféverse.</p>
                        <p>The below document provides a market analysis, including evaluations of six existing event management and community engagement platforms. The analysis culminates in a feature/function matrix comparing these platforms across various criteria.</p>
                        <a href={marketResearch} target="_blank" rel="noopener noreferrer">Download Market Research PDF</a>
                    </div>
                    <div className="one-market-images">
                        <img src={similarProducts} alt="Similar products comparison."/>
                        <img src={marketFeatures} alt="Market features comparison."/>
                    </div>
                </div>

                {/* user personas and user journey map */}
                <div className="one-personas">
                    <div className="one-personas-text">
                        <h3>User Personas & User Journey Map</h3>
                        <p>We created detailed personas to represent our primary users—attendees, café owners, and event organizers. A journey map (based off of a use case) was developed to visualize the user’s experience from discovering an event to attending it.</p> 
                        <a href={userJourney} target="_blank" rel="noopener noreferrer">Download User Journey Map PNG</a>
                    </div>
                    <div className="one-personas-image">
                        <img src={userPersona} alt="User persona."/>
                    </div>
                </div>

                {/* wireframes and prototypes */}
                <div className="one-wireframes">
                    <div className="one-wireframes-text">
                        <h3>Wireframes & Low-Fidelity Prototypes</h3>
                        <p>Our early brainstorming sessions focused on simplifying event discovery and communication. We explored different navigation structures and user flows, which later informed our wireframes. The initial wireframes were drawn by hand, then refined into digital wireframes using Figma. These low-fidelity prototypes were used to map out the app’s primary features and user flows. </p>
                    </div>
                    <div className="one-wireframes-images">
                        <div className="paper-wireframe">
                            <figure id="paper-wireframe">
                                <img src={paperWireframeImg} alt="Paper wireframe."/>
                                <figcaption>Hand-drawn paper wireframes. To see the rest of the wireframes, <a href={paperWireframes} target="_blank" rel="noopener noreferrer">Download Paper Wireframes</a>.</figcaption>
                            </figure>
                        </div>
                        <div className="digital-wireframe">
                            <figure id="digital-wireframe">
                                <img src={digitalWireframes} alt="Digital wireframes."/>
                                <figcaption>Digital wireframes created in Figma. To see the rest of the wireframes, <a href="https://www.figma.com/design/wzHCcfAoGF3yO88Vtp6sn1/CafeVerse?node-id=0-1&t=FM15NsrHEgTIGhNe-1" target="_blank" rel="noopener noreferrer">View Wireframes in Figma</a>.</figcaption> 
                            </figure>
                        </div>
                    </div>
                </div>

                {/* testing and feedback */}
                <div className="one-testing">
                    <h3>Usability Testing</h3>
                    <p>We conducted several rounds of usability testing to identify areas for improvement. We performed the Think-Aloud Protocol and conducted interviews/surveys. This information was necessary in ensuring the app worked in ways that were natural for users.</p>
                </div>

                {/* high-fidelity prototype */}
                <div className="one-prototype">    
                    <div className="one-prototype-mockups">
                        <div className="one-prototype-text">
                            <h3>High-Fidelity Mockups & Prototypes</h3>
                            <p>Based on user feedback, we developed high-fidelity mockups that focused on a clean, user-friendly interface with intuitive navigation. We created three versions to decide the style and color scheme: conservative, moderate, and wild. We then surveyed users asking which design they preferred, and most users liked the moderate design. </p>
                            <p>We used the styling from the moderate mockup to create the full high-fidelity prototype. This final design balanced functionality with aesthetic appeal.</p>
                        </div>
                        <img src={mockups} alt="High-fidelity mockups."/>
                    </div>
                    <p>The following document showcases these designs, feedback, and styling of Caféverse: <a href={prototypingAndDesign} target="_blank" rel="noopener noreferrer">Download Prototyping & Design PDF</a>.</p>
                    
                    <div className="one-prototype-high-fidelity">
                        <img src={highFidelity} alt="High-fidelity prototype."/>
                        <img src={highFidelity2} alt="High-fidelity prototype."/>
                    </div>
                    <p>To see the rest of the high-fidelity prototype, <a href="https://www.figma.com/design/iWDS0swl2aznG1TwfH7SFw/CafeVerse-High-Fidelity-Prototype?node-id=0-1&t=OU5ZrQmPlTcGc9Q4-1" target="_blank" rel="noopener noreferrer">View Prototype in Figma</a>.</p>
                </div>

                {/* lessons learned */}
                <div className="one-lessons">
                    <h3>Lessons Learned & Project Successes</h3>
                    <p>This project taught me the importance of iterative design and user feedback in creating a product that meets user needs. The project was successful in achieving a user-friendly interface, and I gained significant experience in user-centered design practices.</p>
                </div>
            </div>
        </div>
    );
}

export default Project1;