import React, { useRef, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import './App.css';

// components
import Home from './components/Home';
import Project1 from './components/Project-1';
import Project2 from './components/Project-2';
import Project3 from './components/Project-3';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

function App() {
  // variables
  const aboutRef = useRef(null);
  const skillsRef = useRef(null);
  const projectsRef = useRef(null);
  const contactRef = useRef(null);

  // scroll function
  const scrollToSection = (section) => {
    if (section === 'about') {
      aboutRef.current?.scrollIntoView({ behavior: 'smooth' });
    } else if (section === 'skills') {
      skillsRef.current?.scrollIntoView({ behavior: 'smooth' });
    } else if (section === 'projects') {
      projectsRef.current?.scrollIntoView({ behavior: 'smooth' });
    } else if (section === 'contact') {
      contactRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }

  function HomeWrapper() {
    const location = useLocation();

    useEffect(() => {
      if (location.state && location.state.scrollTo) {
        scrollToSection(location.state.scrollTo);
      }
    }, [location]);

    return (
      <Home
        aboutRef={aboutRef}
        skillsRef={skillsRef}
        projectsRef={projectsRef}
        contactRef={contactRef}
      />
    );
  }

  return (
    <Router>
      <div>
        <Navbar scrollToSelection={scrollToSection}/>
        <Routes>
          <Route path="/" element={<HomeWrapper />} />
          <Route path="/cafeverse" element={<Project1 />} />
          <Route path="/queendom-ent" element={<Project2 />} />
          <Route path="/ucfrugal" element={<Project3 />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;