import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import "../styles/Projects.css";

// get images
import cafeverse from '../assets/images/projects/cafeverse.svg';
import queendom from '../assets/images/projects/queendoment.svg';
import ucfrugal from '../assets/images/projects/ucfrugal.svg';

function Projects() {
  return (
    <div className="projects-section" id="projects">
      <div className="projects-heading-container">
        <h1 id="projects-heading-1">My</h1>
        <h1 id="projects-heading-2">Projects</h1>
      </div>
      <div className="projects-content"> 
        {/* Project 1 */}
        <div className="project">
          <h2>Cafeverse</h2>
          <RouterLink to="/cafeverse" style={{textDecoration: 'none', display: 'block'}}>
            <div className="project-item">
              <div className="project-image">
                <img src={cafeverse} alt="Three phone screens showing the UI of the project."/>
              </div>
              <h3>Fandom Event Platform UX</h3>
              <div className="card-text">
                Figma-designed user interface for a fandom event management platform. Connects organizers, attendees, venues, and vendors. Facilitates event creation, discovery, and participation for pop-up fandom gatherings.
              </div>
              <div className="projects-link">
                <RouterLink to="/cafeverse">View Project</RouterLink>
              </div>
            </div>
          </RouterLink>
        </div>
        {/* Project 2 */}
        <div className="project">
          <h2>Queendom Ent.</h2>
          <RouterLink to="/queendom-ent" style={{textDecoration: 'none', display: 'block'}}>
            <div className="project-item">
              <div className="project-image">
                <img src={queendom} alt="Project 2" />
              </div>
              <h3>K-Pop Music Company Website UI</h3>
              <div className="card-text">
                Website designed using Figma for a fictional K-Pop music company. Supports and promotes talented female K-Pop artists, catering to and appealing to fans of girl groups and women soloists.
              </div>
              <div className="projects-link">
                <RouterLink to="/queendom-ent">View Project</RouterLink>
              </div>
            </div>
          </RouterLink>
        </div>
        {/* Project 3 */}
        <div className="project">
          <h2>UCFrugal</h2>
          <RouterLink to="/ucfrugal" style={{textDecoration: 'none', display: 'block'}}>
            <div className="project-item">
              <div className="project-image">
                <img src={ucfrugal} alt="Project 3" />
              </div>
              <h3>UCF Classified-Advertising Site</h3>
              <div className="card-text">
                Senior cornerstone project developed with a team of five. Designed and implemented a classified-advertising website tailored for UCF students, staff, and faculty. Facilitates buying, selling, and trading within this community.
              </div>
              <div className="projects-link">
                <RouterLink to="/ucfrugal">View Project</RouterLink>
              </div>
            </div>
          </RouterLink>
        </div>
      </div>
    </div>
  );
}

export default Projects;
