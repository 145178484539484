import React, { useEffect } from "react";
import '../styles/Project-3.css';

import titleImg from '../assets/images/project-3/ucfrugal-title.svg'; 
import caseStudy from '../assets/pdfs/project-3/SiteCaseStudy_LindseyNielsen.pdf';
import discoveryPDF from '../assets/pdfs/project-3/Discovery-Phase.pdf';

import wireframes from '../assets/images/project-3/ucfrugal-wireframes.svg';
import designGuide from '../assets/pdfs/project-3/design_guide.pdf';
import styleGuide from '../assets/images/project-3/StyleGuideSheet.png';
import lfPrototype from '../assets/images/project-3/lf-prototype.svg';

import useCases from '../assets/pdfs/project-3/Use_Cases.pdf';
import testingPlan from '../assets/pdfs/project-3/Testing_Plan.pdf';
import informationArchitecture from '../assets/images/project-3/Information-Architecture.png';
import contentInventory from '../assets/pdfs/project-3/Content_Inventory.pdf';

import hfPrototype from '../assets/images/project-3/hf-prototype.svg';
import finalDesignGuide from '../assets/pdfs/project-3/final-design-guide.pdf';
import finalStyleGuide from '../assets/images/project-3/final-style-guide.png';
import testingReport from '../assets/pdfs/project-3/Testing_Report.pdf';
import finalPageTables from '../assets/pdfs/project-3/final-page-tables.pdf';
import finalSiteMap from '../assets/images/project-3/final-site-map.png';
import finalStrategyGuide from '../assets/pdfs/project-3/final_strategy_guide.pdf';

import frontEnd from '../assets/pdfs/project-3/FrontEnd_Development.pdf';
import backEnd from '../assets/pdfs/project-3/BackEnd_Development.pdf';
import testingDebugging from '../assets/pdfs/project-3/Testing_Debugging.pdf';

const Project3 = () => {

    useEffect(() => {
        // Scroll to the top of the page when this component mounts
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <div className="project3-section">
            <div className="project3-content">

                {/* title, subtitle, and teammates */}
                <div className="three-intro-section">
                    <div className="three-intro-image">
                        <img src={titleImg} alt="Phone screens showing the UI of the project."/>
                    </div>
                    <div className="three-intro-text">
                        <div className="three-title">
                            <div className="project3-heading-container">
                                <h1 id="project3-heading-1">UCF</h1>
                                <h1 id="project3-heading-2">rugal</h1>
                            </div>
                            <h2 className="three-intro-text">UCF Classified-Advertising Site</h2>
                            <p className="three-intro-text">Teammates: <a href="https://www.linkedin.com/in/sophieagudelo/" target="_blank" rel="noreferrer noopener">Sophie Agudelo</a>, <a href="https://www.linkedin.com/in/indexduo-jingli/" target="_blank" rel="noreferrer noopener">Jing Li</a>, <a href="https://www.linkedin.com/in/daniel-martez-23a2912b0/" target="_blank" rel="noreferrer noopener">Daniel Martez</a>, <a href="https://www.linkedin.com/in/jocelyn-m-santos/" target="_blank" rel="noreferrer noopener">Jocelyn Santos</a></p>
                        </div>

                        {/* overview */}
                        <div className="three-overview">
                            <h3 id="overview-h3" className="three-intro-text">Overview</h3>
                            <p className="three-intro-text">UCFrugal is a responsive platform designed for classified ads within the UCF community. It allows users to buy, sell, and trade items with an easy-to-use interface for creating listings, messaging, and searching. The platform includes location-based features and secure authentication, tailored to the needs of UCF students, faculty, and staff.</p>
                        </div>  
                    </div>
                </div>

                {/* prototype on figma */}
                <div className="three-prototype-link">
                    <p className="three-link-line">Check out the high-fidelity prototype on Figma!</p>
                    <a href="https://www.figma.com/proto/mq9WpxGUwfyr0p8LJUjnc4/Hi-Fi-Prototypes?node-id=209-13062&node-type=canvas&t=o2JtjjzEKVJu6oxo-1&scaling=min-zoom&content-scaling=fixed&page-id=8%3A870&starting-point-node-id=209%3A12613&show-proto-sidebar=1" target="_blank" rel="noopener noreferrer">
                        <button type="button">View Prototype</button>
                    </a>
                </div>

                <div className="three-walkthrough">
                    <p className="three-link-line">Check out the walkthrough YouTube video of the finished site!</p>
                    <a href="https://youtu.be/4GMfTBzlKug?si=DULjfXhvTXg-dAfE" target="_blank" rel="noopener noreferrer">
                        <button type="button">View Video</button>
                    </a>
                </div>

                {/* role and responsibilities */}
                <div className="three-role">
                    <h3>My Role & Responsibilities</h3>  
                    <p>As the lead in design and branding for the UCFrugal project, I was responsible for establishing the visual identity and ensuring consistency across all design elements. I played a key role in developing high-fidelity prototypes and translating our initial concepts into fully designed interfaces. Additionally, I contributed extensively to the project documentation and was actively involved in user testing and research, helping to gather and analyze feedback to refine our platform and enhance its usability.</p>  
                </div>  

                {/* discovery */}
                <div className="three-discovery">
                    <h3>Discovery & Research</h3>
                    <p>This phase of our project was crucial, as it allowed us to define technical and user experience requirements through detailed research and case studies. My role involved evaluating the benefits of a website versus a mobile app, concluding that a responsive website was the best choice based on:</p>
                    <ul>
                        <li><u>Broader Accessibility:</u> A responsive website works across all devices, eliminating the need for seperate app downloads.</li>
                        <li><u>Cost Efficiency:</u> Developing and maintaining a website is more cost-effective than managing multiple app versions.</li>
                        <li><u>Ease of Maintenance:</u> Updates and features can be added easily on a website, unlike mobile apps which require updates through app stores.</li>
                    </ul>
                    <a href={caseStudy} target="_blank" rel="noopener noreferrer">Download Case Study PDF</a>
                    
                    <div className="three-discovery-section">
                        <div className="three-discovery-section-1">
                            <h4>Key Team Contributions</h4>
                            <ul>
                                <li><u>Geolocation</u> (Sophie Agudelo): Researched implementing geolocation for location-specific listings and personalized search results.</li>
                                <li><u>Multi-factor Authentication</u> (Daniel Martez): Focused on integrating multi-factor authentication for enhanced security.</li>
                                <li><u>Accessibility</u> (Jing Li): Ensured compliance with WCAG 2.2 guidelines to make the platform accessible to users with disabilities.</li>
                                <li><u>Information Architecture</u> (Jocelyn Santos): Analyzed existing platforms and used card-sorting to design a user-friendly navigation structure.</li>
                            </ul>
                    
                            <p>This information then helped us create a statement of work and a strategy guide for the project.</p>
                        </div>

                        <div className="three-discovery-section-2">
                            <h4>Statement of Work & Strategy Guide</h4>
                            <p>The statement of work outlined the project's scope, emphasizing the creation of a safe and secure platform for UCF students, faculty, and staff to advertise housing, items for sale, jobs, and events. It highlighted the commitment to accessibility, multi-factor authentication, geolocation features, and responsive design.</p>
                            <p>The strategy guide focused on future content creation and development, including:</p>
                            <ol>
                                <li>Developing visual design aspects such as branding, color schemes, and typography.</li>
                                <li>Creating wireframes and prototypes using tools like Figma to refine the user interface and experience.</li>
                                <li>Implementing a user-friendly multi-factor authentication system, drawing inspiration from UCF's existing system.</li>
                                <li>Conducting user surveys and interviews to gather feedback on the design and functionality.</li>
                            </ol>

                            <p>This guide also included a marketing plan, emphasizing the platform's unique selling proposition of being a safer, more localized alternative to existing classified ad sites. Marketing strategies include utilizing social media platforms, cnducting on-campus interviews, and organizing pop-up "garage sales".</p>
                            <a href={discoveryPDF} target="_blank" rel="noopener noreferrer">Download Statement of Work & Strategy Guide PDF</a>
                        </div>
                    </div>
                </div>

                {/* Design */}
                <div className="three-design">
                    <h3>Design, Wireframing, & Prototyping</h3>
                    <p>Our design process centered on user needs, brand identity, and a mobile-first approach.</p>

                    <div className="three-wireframes">
                        <div className="three-wireframes-text">
                            <h4>Wireframes</h4>
                            <p>We developed wireframes in Figma for mobile, desktop, and tablet views. These wireframes outlined key interactions, including onboarding, account management, listing creation, messaging, and search functions.</p>
                        </div>
                        <div className="three-wireframes-image">
                            <figure>
                                <img src={wireframes} alt="Wireframes for UCFrugal."/>
                                <figcaption>Digital wireframes created in Figma. To see the rest of the wireframes, <a href="https://www.figma.com/design/djIucboKtNmbIfECIVcXb2/UCFrugal-wireframes?node-id=25-86&node-type=frame&t=zFXyl1UtIqPyhIUZ-0" target="_blank" rel="noopener noreferrer">View Wireframes in Figma</a>.</figcaption>
                            </figure>
                        </div>
                    </div>

                    <div className="three-design-guide">
                        <div className="three-design-guide-text">
                            <h4>Design Guide</h4>
                            <p>The visual design guide provided detailed documentation of our branding choices, including UCF's bold black and gold tied in with blue and gray, typography (Montserrat for headings and Roboto for body text), and logo usage. It's purpose was to serve as a reference for maintaining consistency throughout the platform.</p>
                            <a href={designGuide} target="_blank" rel="noopener noreferrer">Download Design Guide PDF</a>
                            <p>We also created a Style Guide to outline the visual design elements in a concise way.</p>
                        </div>
                        <div className="three-design-guide-image">
                            <img src={styleGuide} alt="Style guide for UCFrugal."/>
                        </div>
                    </div>

                    <div className="three-lf-prototype">
                        <div className="three-lf-prototype-text">
                            <h4>Low-Fidelity Prototypes</h4>
                            <p>Low-fidelity prototypes were developed in Figma to test the design’s functionality and usability. These prototypes allowed us to refine the design based on user feedback.</p>
                        </div>
                        <div className="three-lf-prototype-image">
                            <figure>
                                <img src={lfPrototype} alt="Low-fidelity prototype for UCFrugal."/>
                                <figcaption>Low-Fidelity screens. To see the rest of the prototype, <a href="https://www.figma.com/design/LRHMbCukpsB8u1RITUhOe5/Prototypes?node-id=0-1&t=zIC6sbynlcz1O4Zi-1" target="_blank" rel="noopener noreferrer">View Low-Fidelity Prototypes in Figma</a>.</figcaption>
                            </figure>
                        </div>
                    </div>
                </div>

                {/* use cases, testing, and content strategy */}
                <div className="three-use-cases">
                    <h3>Use Cases, Testing, and Content Strategy</h3>
                    <div className="three-use-cases-text">
                        <h4>Use Cases</h4>
                        <p>We created use cases to capture critical user interactions such as registration, listing creation, messaging, and map-based features. These use cases informed the design by highlighting real-world user needs.</p>
                        <a href={useCases} target="_blank" rel="noopener noreferrer">Download Use Cases PDF</a>
                    </div>

                    <div className="three-testing">
                        <h4>Testing Plan</h4>
                        <p>We established a testing plan to evaluate the design's effectiveness, focusong on task accuracy rates and user feedback. Success was defined by achieving a 70-80% accuracy rate in key tasks, with errors categorized as minor, moderate, or major.</p>
                        <a href={testingPlan} target="_blank" rel="noopener noreferrer">Download Testing Plan PDF</a>
                    </div>

                    <div className="site-architecture">
                        <h4>Proposed Site Architecture</h4>
                        <p>A site architecture diagram was created to illustrate the organization and distribution of content, ensuring an intuitive and efficient user experience.</p>
                        <figure>
                            <img src={informationArchitecture} alt="Proposed site architecture for UCFrugal."/>
                            <figcaption>You can also <a href="https://www.figma.com/board/ebBP1mwO2GF18hGAjvC3WZ/Information-Architecture?node-id=0-1&t=1KfXoDwcW76qt1bW-1" target="_blank" rel="noopener noreferrer"> View Information Architecture in Figma</a>.</figcaption>
                        </figure>
                    </div>

                    <div className="content-inventory">
                        <h4>Content Inventory</h4>
                        <p>We compiled a content inventory outlining key assets for the platform, including items for sale (textbooks, cars, electronics), event listings (tailgates, concerts), job postings (retail, food service), housing options (sublets, rentals), and account features (likes, posts, drafts). The feed is organized with categories like recommended, trending, and recently viewed. This inventory provides a clear structure for future development.</p>
                        <a href={contentInventory} target="_blank" rel="noopener noreferrer">Download Content Inventory PDF</a>
                    </div>
                </div>

                {/* Finalization & Prototype Development */}
                <div className="three-finalization">
                    <h3>Finalization & High-Fidelity Prototypes</h3>
                    <p>This phase of the project focused on refining the design and preparing high-fidelity prototypes while incorporating testing feedback.</p>

                    <div className="three-hf-prototype">
                        <div className="three-hf-prototype-text">
                            <h4>High-Fidelity Prototypes</h4>
                            <p>High-fidelity prototypes were developed in Figma to showcase the final design and interactions, using placeholder content. These prototypes were used for user testing.</p>
                        </div>
                        <div className="three-hf-prototype-image">
                            <figure>
                                <img src={hfPrototype} alt="High-fidelity prototype for UCFrugal."/>
                                <figcaption>High-Fidelity screens. To see the rest of the prototype, <a href="https://www.figma.com/design/mq9WpxGUwfyr0p8LJUjnc4/Hi-Fi-Prototypes?node-id=378-3690&t=JYJ8HV7DeSN3a1jQ-1" target="_blank" rel="noopener noreferrer">View High-Fidelity Prototypes in Figma</a>.</figcaption>
                            </figure>
                        </div>
                    </div>

                    <div className="final-design-guide">
                        <h4>Final Design Guide</h4>
                        <p>A final iteration of the design guide (and style guide) was completed, consolidating all design elements to maintain a consistent look and feel across the site.</p>
                        <a href={finalDesignGuide} target="_blank" rel="noopener noreferrer">Download Final Design Guide PDF</a>
                        <div className="final-design-guide-image">
                            <img src={finalStyleGuide} alt="Final style guide for UCFrugal."/>
                        </div>
                    </div>

                    <div className="testing-report">
                        <h4>Testing Mitigation Report</h4>
                        <p>Building on the testing plan from the previous phase, we conducted tests on the high-fidelity prototype. Each member of the team conducted six user tests, accumulating in 30 tests. The report identified and addressed issues related to usability and functionality.</p>
                        <a href={testingReport} target="_blank" rel="noopener noreferrer">Download Testing Report PDF</a>
                    </div>

                    <div className="strategy-guide">
                        <h4>Final Strategy Guide</h4>
                        <p>This guide included recommendations for transitioning the high-fidelity prototype to a fully distributable website. We outlined key development steps, such as setting up hosting and ensuring responsive design compatibility.</p>
                        <a href={finalStrategyGuide} target="_blank" rel="noopener noreferrer">Download Final Strategy Guide PDF</a>
                    </div>

                    <div className="page-tables-site-map">
                        <h4>Final Page Tables & Site Map</h4>
                        <p>We developed final versions of the site's text content, presenting it without styling to ensure readability and clarity. This allowed for a focus on content before applying any design elements.</p>
                        <a href={finalPageTables} target="_blank" rel="noopener noreferrer">Download Final Page Tables PDF</a>
                        <div className="final-site-map">
                            <figure>
                                <img src={finalSiteMap} alt="Final site map for UCFrugal."/>
                                <figcaption>For better visibility, <a href={finalSiteMap} target="_blank" rel="noopener noreferrer" >Download Final Site Map PNG</a>.</figcaption>
                            </figure>
                        </div>
                    </div>
                </div>

                {/* Front-End Development */}
                <div className="front-end">
                    <h3>Front-End Development</h3>
                    <p>The front-end development phase achieved full functionality for all key pages, including login, account settings, listings, and search results. By leveraging WordPress, the team was able to quickly implement the core features using the Radius Directory theme and additional plugins. Flywheel hosting supported asynchronous collaboration, allowing real-time updates to the site by all team members​.</p>
                
                    <div classsName="wordpress">
                        <h4>Wordpress Implementation</h4>
                        <p>The team chose to use WordPress for the project to apply skills learned in another class and due to time constraints within the semester. WordPress allowed for quick setup and customization through themes and plugins, which made the process of implementing key features such as account management, listing creation, and search functionality easy. The Radius Directory theme was selected due to its alignment with the high-fidelity designs and responsive capabilities. Plugins like Classified Listing, Ivory Search, and Direct Messaging were integrated to extend the platform’s functionality.</p>
                    </div>

                    <div className="design-assets">
                        <h4>Design Assets</h4>
                        <p>Design assets, including images, icons, and fonts, were integrated into the WordPress theme, ensuring consistency with the original design guide. The assets aligned with the overall branding, maintaining a cohesive and professional look throughout the site​.</p>
                    </div>

                    <div className="user-interface">
                        <h4>User Interface Design</h4>
                        <p>The UI design focused on creating an intuitive, responsive interface across devices. Key user flows were designed to be simple and efficient, with prominent features like the search bar, category tiles, and "Add Listing" buttons guiding users through tasks like browsing, posting, and managing their listings.</p>
                    </div>

                   <div className="fe-testing">
                        <h4>Testing and Debugging</h4>
                        <p>Testing was conducted to ensure cross-browser compatibility and responsive behavior across different devices. Issues related to layout and navigation were identified and resolved through thorough testing, ensuring the user interface remained functional and user-friendly.</p>
                   </div>

                    <a href={frontEnd} target="_blank" rel="noopener noreferrer">Download Front-End Development PDF</a>
                </div>

                {/* Back-End Development */}
                <div className="back-end">
                    <h3>Back-End Development</h3>
                    <p>he back-end development for UCFrugal was powered by WordPress with a variety of plugins. Flywheel hosting supported the server-side operations, allowing for caching, web server processing, PHP execution, and database management. The MySQL database was used to store all user and content data, facilitating the classified listings and messaging systems. Plugins were customized using hooks and filters to meet the platform's needs while maintaining scalability and security.</p>
                
                    <div className="server-side-architecture">
                        <h4>Server-Side Architecture</h4>
                        <p>The server-side architecture was built using PHP, hosted on Flywheel's platform, providing a robust and scalable backend. It included several key layers:</p>

                        <ul>
                            <li><u>Caching Layer:</u> FlyCache, powered by Fastly, optimized data transfer between the front-end and back-end.</li>
                            <li><u>Web Server Layer:</u> NGINX efficiently managed event-driven and asynchronous operations to handle large amounts of user-generated data.</li>
                            <li><u>PHP Layer:</u> PHP-FPM enhanced performance by enabling fast processing of content.</li>
                            <li><u>Database Layer:</u> MySQL, with the InnoDB storage engine, managed structured data such as posts, messages, and user information.</li>
                        </ul>

                        <p>Server-side functionality was supported by PHP and WordPress hooks, which managed various user interactions. Plugins like Login WP and User Menus handled user authentication, redirections, and conditional menus. Elementor dynamically generated content pages based on user inputs, ensuring efficient performance and smooth interactions between the server, database, and front-end.</p>
                    </div>

                    <div className="database-integration">
                        <h4>Database Integration</h4>
                        <p>WordPress plugins like Classified Listing, Better Messages, and Ivory Search relied heavily on the MySQL database to store user-generated content, messages, search configurations, and form submissions. Each plugin utilized its own schema to store various types of data, from user listings to messaging metadata. The database integration allowed for seamless retrieval and rendering of data in real-time.</p>
                    </div>

                    <div className="strengths-weaknesses">
                        <h4>Technical Strengths & Weaknesses</h4>
                        <p>Strengths: The modularity provided by WordPress and its plugins offered extensive customization without altering core code, allowing for scalability and maintainability. Hooks and filters enabled flexible functionality additions.</p>
                        <p>Weaknesses: The reliance on multiple third-party plugins introduced potential security risks and performance bottlenecks. Additionally, high traffic could cause server-side processing delays if caching strategies were not fully optimized​.</p>
                    </div>

                    <a href={backEnd} target="_blank" rel="noopener noreferrer">Download Back-End Development PDF</a>
                </div>

                <div className="last-testing">
                    <h3>Testing & Debugging</h3>
                    <p>In this stage, we implemented a testing plan focused on ensuring the core functions of the website meet user expectations. Key functionalities tested included user sign-in, listing interactions, listing creation and editing, messaging, and site navigation. The goal was to ensure users can efficiently use the site to manage listings and communicate effectively.</p>
                    <p>Our testing involved ten user tests conducted by interviewing two users each from our group. We identified and resolved several issues primarily related to accessibility and usability. Notable improvements included adjustments to color contrast for better accessibility and updates to enhance user registration and listing processes. A significant challenge was resolving email-related issues due to Flywheel’s hosting limitations, which we addressed through direct support.</p>
                    <a href={testingDebugging} target="_blank" rel="noopener noreferrer">Download Testing & Debugging PDF</a>
                </div>

                <div className="three-lessons">
                    <h3>Lessons Learned & Project Successes</h3>
                    <p>Working in a group setting taught us the importance of effective time management and trust in team members. Creating detailed documentation ensured consistency throughout the project, while setting soft deadlines kept us on track to meet the required milestones. We also learned the value of conducting user interviews and integrating their feedback to enhance our design. Also, maintaining organized file management was essential for collaboration.</p>
                    <p>Our team successfully developed UCFrugal by integrating client-side and server-side technologies using WordPress and various plugins. Starting with a core idea, we progressed through developing wireframes and prototypes, eventually implementing and finalizing the product. We maintained consistent functionality between the front-end and back-end and made continuous improvements based on feedback. Testing played a significant role in refining the platform's accessibility and usability, helping us deliver a polished and user-friendly final product.</p>
                </div>
            </div>
        </div>
    );
}

export default Project3;