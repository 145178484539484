import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../styles/Navbar.css';
import logo from '../assets/logos/logo.PNG';
import hamburger from '../assets/icons/hamburger-menu.svg';

const Navbar = ({ scrollToSelection }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isNavOpen, setIsNavOpen] = useState(false);

    const handleScroll = (section) => {
        setIsNavOpen(false);
        if (location.pathname === '/') {
            scrollToSelection(section);
        } else {
            navigate('/', { state: { scrollTo: section } });
        }
    };

    const handleHome = () => {
        setIsNavOpen(false);
        if (location.pathname === '/') {
            window.scrollTo(0, 0);
        } else {
            navigate('/');
        }
    };

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    }

    return (
        <nav className="navbar">
            <div className="hamburger-icon" onClick={toggleNav}>
                <img src={hamburger} alt="Hamburger icon." />
            </div>
            <ul className={`nav-links ${isNavOpen ? 'active' : ''}`}>
                <li><Link to="/" onClick={handleHome}>home</Link></li>
                <li><Link to="/" onClick={() => handleScroll('about')}>about</Link></li>
                <li><Link to="/" onClick={() => handleScroll('skills')}>skills</Link></li>
                <li><Link to="/" onClick={() => handleScroll('projects')}>projects</Link></li>
                <li><Link to="/" onClick={() => handleScroll('contact')}>contact</Link></li>
            </ul>
            <div className="logo">
                <img src={logo} alt="Lindsey's logo." />
            </div>
        </nav>
    );
}

export default Navbar;