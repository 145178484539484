// src/components/Skills.js

import React from 'react';
import "../styles/Skills.css";
import image from '../assets/images/skills/2-cropped.svg'

function Skills() {
  return (
    <div className="skills-section" id="skills">
        <div className="skills-heading-container">
            <h1 id="skills-heading-1">My</h1>
            <h1 id="skills-heading-2">Skills</h1>
        </div>
        <div className="skills-content">
            <img src={image} className="skills-image" alt="" />
            <div className="skills-text">
                <div className="skill-category">
                    <h2>Design</h2>
                        <ul>
                            <li>UI/UX Design Experience</li>
                            <li>Experience with Graphic Design</li>
                            <li>Strong Visual Design Skills</li>
                            <li>Proficient with Figma</li>
                            <li>Proficient with Adobe Creative Suite, particularly Photoshop and Illustrator</li>
                            <li>Skilled with Canva</li>
                    </ul>
                </div>
                <div className="skill-category">
                    <h2>Development</h2>
                    <ul>
                        <li>Full Stack Development Experience</li>
                        <li>Strong HTML & CSS Skills</li>
                        <li>Proficient with JavaScript</li>
                        <li>Experience with React, React Native, and Node.js</li>
                        <li>Experience with C and Java</li>
                        <li>Familiar with MySQL and MongoDB</li>
                        <li>Proficient with WordPress</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Skills;
