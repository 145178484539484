import React, { useEffect } from "react";
import '../styles/Project-2.css';

import queendomTitle from '../assets/images/project-2/queendom-title.svg';
import styleGuide from '../assets/images/project-2/qe-style-guide.svg';
import aboutWireframe from '../assets/images/project-2/about_page.jpg';
import homeWireframe from '../assets/images/project-2/home_page.jpg';
import artistsWireframe from '../assets/images/project-2/artists_page.jpg';
import homePage from '../assets/images/project-2/home-page.svg';
import aboutPage from '../assets/images/project-2/about-page.svg';
import artistsPage from '../assets/images/project-2/artists-page.svg';

const Project2 = () => {

    useEffect(() => {
        // scroll to the top of the page when this component mounts
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="project2-section">
            <div className="project2-content">

                {/* title, subtitle, and teammates */}
                <div className="two-intro-section">
                    <div className="two-intro-image">
                        <img src={queendomTitle} alt="Tablet screens showing the UI of the project."/>
                    </div>
                    <div className="two-intro-text">
                        <div className="two-title">
                            <div className="project2-heading-container">
                                <h1 id="project2-heading-1">Queendom</h1>
                                <h1 id="project2-heading-2">Entertainment</h1>
                                <h1 id="project2-heading-2-shortened">Ent.</h1>
                            </div>
                            <h2 className="two-intro-text">K-Pop Music Company Website UI</h2>
                        </div>

                        {/* overview */}
                        <div className="two-overview">
                            <h3 id="two-overview-h3" className="two-intro-text">Overview</h3>
                            <p className="two-intro-text">Queendom Entertainment is a website designed for a fictional K-Pop music company that promotes its artists and engages fans. The platform enables fans to discover new music, stay updated on their favorite artists, and learn about the company behind them. It focuses on building a strong connection between the artists and their audience.</p>
                        </div>  
                    </div>   
                </div>

                {/* prototype on figma */}
                <div className="two-prototype-link">
                    <p id="two-prototype-line">Check out the high-fidelity prototype on Figma!</p>
                    <a href="https://www.figma.com/proto/FQzOoHi0iq6owvIkCMN0hk/Queendom-Ent-Hi-Fi-Designs?node-id=2-2&node-type=CANVAS&t=udNnJaMiYf0lMweF-1&scaling=min-zoom&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=2%3A2" target="_blank" rel="noopener noreferrer">
                        <button type="button">View Prototype</button>
                    </a>
                </div>

                {/* research and discovery */}
                <div className="two-research-section">
                    <h3>Research & Discovery</h3>
                    <p>The project began with research into K-Pop music companies and their target audience. This investigation helped identify key elements of successful K-Pop websites and informed the project's scope, which focused on creating three essential pages: home, about, and artist profiles. The brand identity was established as a women-run company supporting female K-Pop artists, with an aesthetic direction emphasizing pink, elegant design to cater to fans of K-Pop girl groups and women soloists.</p>
                </div>

                {/* goals and target audience */}
                <div className="two-goals-audience-section">
                    <div className="two-goals">
                        <h3>Goals</h3>
                        <ul>
                            <li>Implement a dark mode design</li>
                            <li>Create an engaging user experience</li>
                            <li>Showcase and promote artists and their music</li>
                        </ul>
                    </div>
                    <div className="two-audience">
                        <h3>Target Audience</h3>
                        <ul>
                            <li><u>Fans:</u> Explore latest updates, music releases, and fan activities related to their favorite artists</li>
                            <li><u>Media Outlets:</u> Look for the latest news and updates about artists and events</li>
                            <li><u>Artists:</u> Access information about the company and its services</li>
                        </ul>
                    </div>
                </div>

                {/* wireframes */}
                <div className="two-wireframes-section">
                    <div className="two-wireframes-text">
                        <h3>Wireframes</h3>
                        <p>The design process kicked off with the creation of paper prototypes to visualize the overall layout and structure of the website. These initial sketches explored ideas for the home page layout, incorporating an image slider and social media integration. The about page wireframe featured staff members and executives, while the artist profiles page showcased images and album information. </p>
                    </div>
                    <div className="two-wireframes-images">
                        <div className="wireframe-image">
                            <img src={homeWireframe} alt="Home page wireframe."/>
                            <p className="caption">Home Page</p>
                        </div>
                        
                        <div className="wireframe-image">
                            <img src={aboutWireframe} alt="About page wireframe."/>
                            <p className="caption">About Page</p>
                        </div>

                        <div className="wireframe-image">
                            <img src={artistsWireframe} alt="Artists page wireframe."/>
                            <p className="caption">Artists Page</p>
                        </div>
                    </div>
                </div>

                {/* design process */}
                <div className="two-design-section">
                    <div className="two-design-text">  
                        <h3>Design Process</h3>
                        <p>The design process started with the development of the company logo as the foundation for the visual identity. Font selection was crucial, with Higuen Elegant Script and Sloop Script One chosen for headings and logo, Montserrat Regular for subtitles, and Montserrat Light for body text. The color palette was carefully curated to reflect the brand's personality, featuring pink (Hex #D8798F), gold (Hex #9D7A49), off-white (Hex #FFF8E8), and dark gray (Hex #242426).</p>
                        <p>Individual page designs were then created, each with unique features:</p>
                        <ul>
                            <li>Home: image slider, Instagram feed, quick links</li>
                            <li>About: staff list complete with photos, names, and job titles</li>
                            <li>Artist Profiles: image slider to create an appealing artist showcase</li>
                        </ul>
                        <p>I designed with dark mode in mind, wanting to delve into a new learning experience. Throughout the process, maintaining a consistent theme and navigation across all pages was a priority to ensure a cohesive and user-friendly experience.</p>
                    </div>
                    <div className="two-design-images">
                        <figure>
                            <img src={styleGuide} alt="Queendom Entertainment style guide."/>
                            <figcaption>Queendom Entertainment Style Guide</figcaption>
                        </figure>
                    </div>
                </div>

                {/* prototypes */}
                <div className="two-prototypes-section">
                    <div className="two-prototypes-text">
                        <h3>Prototypes</h3>
                        <p>High-fidelity mockups were created using Figma, followed by the development of an interactive prototype with clickable elements. These prototypes provided a comprehensive view of the final design and user flow.</p>
                        <a href="https://www.figma.com/design/FQzOoHi0iq6owvIkCMN0hk/Queendom-Ent-Hi-Fi-Designs?node-id=0-1&t=NFLC2O0R9nUpPPDX-1" target="_blank" rel="noopener noreferrer">View Designs in Figma</a>
                    </div>
                    <div className="two-prototypes-images">
                        <div className="prototype-image-section">
                            <div className="prototype-image">
                                <img src={homePage} alt="Home page wireframe."/>
                                <p className="caption">Home Page</p>
                            </div>
                        
                            <div className="prototype-image">
                                <img src={aboutPage} alt="About page wireframe."/>
                                <p className="caption">About Page</p>
                            </div>
                        </div>

                        <div className="prototype-image">
                            <img src={artistsPage} alt="Artists page wireframe."/>
                            <p className="caption">Artists Page</p>
                        </div>
                    </div>
                </div>

                {/* lessons learned and project successes */}
                <div className="two-lessons-section">
                    <h3>Lessons Learned & Project Successes</h3>
                    <p>This project, completed within a challenging 8-hour time constraint, provided valuable experience in user interface design. The successful implementation of dark mode for the first time was a significant achievement and learning opportunity.</p>
                    <p>Queendom Entertainment resulted in a visually appealing and functional website that effectively caters to the target audience of K-Pop fans. However, areas for future improvement were identified, including the incorporation of more interactive elements and multimedia content, as well as the addition of a merchandise page for albums, clothing, and other fan items.</p>
                    <p>Overall, this project deepened my understanding of the design process and problem-solving strategies. </p>
                </div>
            </div>
        </div>
    );
}

export default Project2;