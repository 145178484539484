import React, { useState } from 'react';
import "../styles/Contact.css";
import image from '../assets/images/contact/contact.svg'

function Contact() {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('Sending...');
    console.log('Submitting form data:', formData);
    try {
      console.log('Sending POST request to http://localhost:3001/send');
      const response = await fetch('http://localhost:3001/send', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      });

      console.log('Received response:', response);
      console.log('Response status:', response.status);
      console.log('Response headers:', response.headers);

      let result;
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        result = await response.json();
        console.log('Parsed JSON response:', result);
      } else {
        const text = await response.text();
        console.log('Received non-JSON response:', text);
        result = { message: text };
      }

      if (!response.ok) {
        throw new Error(result.message || result.error || 'An unknown error occurred');
      }

      setStatus(result.message);
      setFormData({ name: '', email: '', message: '' }); // Clear form
    } catch (error) {
      console.error('Error:', error);
      setStatus(`An error occurred: ${error.message}`);
    }
  };

  return (
    <div className="contact-section">
      <div className="contact-heading-container">
        <h1 id="contact-heading-1">Contact</h1>
        <h1 id="contact-heading-2">Me</h1>
      </div>

      <div className="contact-content">
        <div className="contact-form">
          <div className="contact-text">
            <h2>Let's Connect!</h2> 
            <p>If you're interested in working with me or have any questions, feel free to send me a message using the form below.</p>
          </div>
          <form onSubmit={handleSubmit}>
            <input type="text" id="name" name="name" placeholder="Name" required value={formData.name} onChange={handleChange} />
            <input type="email" id="email" name="email" placeholder="Email" required value={formData.email} onChange={handleChange} />
            <textarea id="message" name="message" placeholder="Message" required value={formData.message} onChange={handleChange}></textarea>
            <div className="button-container">
              <button type="submit">Submit</button>
            </div>
          </form>
          {status && <p className="status-message">{status}</p>}
        </div>
        <div className="contact-image">
          <img src={image} alt="purple jellyfish" />
        </div>
      </div>
    </div>
  );
}

export default Contact;