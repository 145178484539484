import React from "react";


// sections
import About from "./About";
import Skills from "./Skills";
import Projects from "./Projects";
import Contact from "./Contact";

const Home = ({ aboutRef, skillsRef, projectsRef, contactRef }) => {
    return (
        <div>   
            <section ref={aboutRef} id="about"><About /></section>
            <section ref={skillsRef}id="skills"><Skills /></section>
            <section ref={projectsRef} id="projects"><Projects /></section>
            <section ref={contactRef} id="contact"><Contact /></section>
        </div>
    );
}

export default Home;