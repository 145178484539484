// about section

import React from 'react';
import "../styles/About.css";
import image from '../assets/images/about/about-image.svg';
import sparkle from '../assets/images/about/about-sparkle.svg';

function About() {
  return (
    <div className = "about-section" id="about">
        <div className="about-heading-container">
            <h1 id="about-heading-1">About</h1>
            <h1 id="about-heading-2">Me</h1>
        </div>
        <div className="about-content">
            <div className="about-text">
                <div className="heading-container">
                  <h1 id="heading-1">Lindsey</h1>
                  <h1 id="heading-2">Nielsen</h1>
                </div>
                <h3>UI/UX Designer & Full Stack Developer</h3>
                <p> Hello! I recently graduated from the University of Central Florida in August 2024 
                    with a B.A. in Digital Media - Web and Social Platforms. Originally from Sammamish, 
                    Washington, I moved to Florida to attend UCF and have now returned home. I am passionate about creating beautiful, 
                    user-friendly, and intuitive designs, as well as developing the functionality to bring them to life.
                    <br></br>  <br></br>
                    I am always looking for new opportunities to learn and grow as a designer, developer, and as a person. 
                    Having completed my degree, I am now actively seeking exciting and challenging opportunities 
                    in the Seattle area. I am thrilled to be back home and eager to start this new chapter in my career. 
                </p>
            </div>
            <div className="about-image">
                <img src={sparkle} alt="sparkle" className="sparkle"/>
                <img src={image} alt="Lindsey Nielsen" className="lindsey"  />
                
            </div>
        </div>

    </div>
  );
}

export default About;
