import React from 'react';
import '../styles/Footer.css';
import linkedin from '../assets/icons/linkedin.svg';
import github from '../assets/icons/github.svg';

const Footer = () => {
    return (
        <footer className="footer">
            <p>© 2024 Lindsey Nielsen</p>
            <div className="icons">
                <a href="https://www.linkedin.com/in/lindsey-nielsen-97217b206/" target="_blank" rel="noreferrer noopener">
                    <img src={linkedin} alt="LinkedIn Logo" className="icon" />
                </a>
                <a href="https://github.com/lindsey-nielsen">
                    <img src={github} alt="GitHub Logo" className="icon" />
                </a>
            </div>
        </footer>
    );
}

export default Footer;
